import { api } from "@/services";

// ONE TRUE SOURCE
const state = {
  loginStatus: "noAuth",
  token: localStorage.getItem("access_token") || null,
};

// COMMIT N' TRACK STATE CHANGES
const mutations = {
  SET_LOGIN_STATUS(state, payload) {
    state.loginStatus = payload;
  },
  SET_TOKEN(state, payload) {
    state.token = payload;
  },
  DESTROY_TOKEN(state) {
    state.token = null;
  },
};

// METHODS LOGICS, CALL MUTATIONS
const actions = {
  async sendCredentials(context, credentials) {
    context.commit("SET_LOGIN_STATUS", "validating");
    await api
      .post("auth/local", {
        identifier: credentials.username,
        password: credentials.password,
      })
      .then((response) => {
        const token = response.data.jwt;
        localStorage.setItem("access_token", token);
        context.commit("SET_TOKEN", token);
        context.commit("SET_LOGIN_STATUS", "auth");
      })
      .catch(() => {
        context.commit("SET_LOGIN_STATUS", "noAuth");
      });
  },
  destroyToken(context) {
    if (context.getters.loggedIn) {
      localStorage.removeItem("access_token");
      context.commit("DESTROY_TOKEN");
    }
  },
  async sendRecovery(context, email) {
    await api
      .post("auth/forgot-password", {
        email: email,
      })
      .then(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message:
              "Para dar continuidade com a recuperação de senha acesse o Link enviado para seu email.",
          },
          { root: true }
        );
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message:
              "Desculpe, não indentificamos nenhum usuário com este endereço.",
          },
          { root: true }
        );
      });
  },
  async sendNewPass(context, data) {
    await api
      .post("auth/reset-password", {
        code: data.token,
        password: data.password,
        passwordConfirmation: data.password,
      })
      .then(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "success",
            message: "Senha Alterada com sucesso!",
          },
          { root: true }
        );
      })
      .catch(() => {
        context.dispatch(
          "toast/sendMessage",
          {
            type: "error",
            message: "Desculpe, não pudemos atualizar sua senha.",
          },
          { root: true }
        );
      });
  },
};

// COMPUTED, FILTERS, MASKS, FORMAT
const getters = {
  loggedIn(state) {
    return state.token !== null;
  },
  getToken(state) {
    return state.token;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
